.buttonContainer {
  display: flex;
  -ms-grid-column: 1vw;
  grid-column-gap: 1vw;
}
.Heading {
  color: #0064c1;
  font-size: 15px;
  text-align: left;
  font-weight: 600;
  /* margin-top: 1vw; */
  margin-left: 1vw;
  margin-bottom: 2vw;
}
.Chart {
  margin-top: -3vw;
}
.imageSize {
  width: 4vw;
  height: 4vw;
  margin: 2vw;
  margin-top: -0.7vw;
  margin-bottom: 1vw;
}
.imageSize img {
  width: 100%;
  height: 100%;
}
.ModalImage {
  max-width: 35vw !important;
  max-height: 35vw !important;
  /* border-radius: 50%;
    border: 5px solid white; */
}
.ModalImage img {
  height: 100%;
  width: 100%;
  /* border-radius: 50%;
    border: 5px solid white; */
}
.RainDataSummery {
  margin-top: 110px;
}
@media (min-width: 600px) {
  .imageSize {
    width: 3vw;
    height: 3vw;
    margin: 2vw;
    margin-top: -0.7vw;
    margin-bottom: 1vw;
  }
  .ModalImage {
    max-width: 35vw !important;
    max-height: 35vw !important;
    /* border-radius: 50%;
        border: 5px solid white; */
  }
}
