.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.MuiDialogContent-root {
  overflow: hidden !important;
}

.paginate {
  margin: auto !important;
}

.card-title {
  text-align: left;
}

.success-btn {
  color: green !important;
  border-color: limegreen !important;
}

.align-self-center {
  align-self: center !important;
}

.m-0 {
  margin: 0 !important;
}

.m-23-10 {
  margin: 23px 10px !important;
}

.intelligence-fields {
  font-size: 20px;
  text-align: center;
  background: #0064c1;
  color: white;
}

.primary-tab {
  background: #0064c1;
  color: white;
  border-radius: 2px;
}

.tab-fonts {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.w-65 {
  width: 65%;
}

.w-77 {
  width: 77% !important;
}

.margin-auto {
  margin: auto !important;
}

.text-align-right td:nth-child(even) {
  text-align: right !important;
}

.pd-t-5 {
  padding-top: 5%;
}

.pd-t-8 {
  padding-top: 8%;
}

.device-dashboard-table th {
  font-weight: bold !important;
}

.last-updated {
  text-align: right;
  margin-bottom: 2%;
}

.filter-container {
  display: flex !important;
  margin: 0 15px !important;
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.device-card-header {
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.p-0 {
  padding: 0 !important;
}

.p-t-0 {
  font-size: 33px;
  text-align-last: center;
  margin-top: 29px;
  padding-top: 0 !important;
}

.device-dashboard-filters {
  grid-gap: 2% !important;
}

.intelligence-card-header {
  height: 25px;
  font-size: 13px;
}

.intelligence-card td {
  font-size: 11px !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.success-msg {
  margin-bottom: 0;
  color: green;
}

.error-msg {
  margin-bottom: 0;
  color: red;
}

.auto-report-card {
  margin-top: 30px !important;
}

.auto-report-card h6 {
  color: #8e24aa;
  font-size: 19px;
  margin: 5px 0px -5px 0px;
  width: 100%;
}

.flex {
  display: flex;
}

.flex-space-between {
  justify-content: space-between;
}

.device-link {
  color: darkblue;
  cursor: pointer;
}

.device-dashboard-paginate {
  inline-size: fit-content;
  margin: 5px auto auto auto !important;
}

.dev-card0 {
  background: #005384 !important;
}

.dev-card1 {
  background: #008d4c !important;
}

.dev-card2 {
  background: #db8b0b !important;
}

.dev-card3 {
  background: #d33724 !important;
}

.dev-card-body0 {
  min-height: 403px;
  background: aliceblue !important;
}

.dev-card-body1 {
  height: 403px;
  background: #f0fff0 !important;
}

.dev-card-body2 {
  min-height: 403px;
  background: #fff1db !important;
}

.dev-card-body3 {
  min-height: 403px;
  background: #ffeae8 !important;
}

.p-0 .MuiGrid-root {
  padding: 0 !important;
}

.p-b-0 {
  padding-bottom: 0px !important ;
}

.MuiTableCell-body {
  overflow-wrap: break-word !important;
}

.login-page {
  height: 104vh;
  overflow: hidden;
  margin-top: -30px;
  background-image: url('./assets/img/login-bg.jpg');
  background-size: cover;
}

.login-page-card {
  padding-top: 40px;
}

.auto-report-field {
  margin-top: 15px;
}

.AddCommand {
  margin: 15px 0 0 0;
  text-align: right;
}

.AddCommandButton {
  margin-top: 20px !important;
}

.CommandField {
  margin-top: 15px !important;
}

.battery-container {
  display: flex;
  justify-content: center;
  height: 223px;
  align-items: flex-start;
}

.battery-container img {
  margin-left: 3%;
  height: 162px;
  width: 100px;
}

.battery-percent {
  margin-left: 12px;
  bottom: 12%;
  color: #607d8b;
  font-size: 22px;
  font-weight: bold;
  position: absolute;
}

.battery-percent1 {
  margin-left: 8px;
  bottom: 12%;
  color: #607d8b;
  font-size: 22px;
  font-weight: bold;
  position: absolute;
}

.battery-level {
  align-self: center;
  width: max-content;
}

.dev-card-body1 .battery-level {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.battery-img-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imei-cont {
  margin: 15px 0px;
}

.err-hypen {
  font-size: 33px;
  text-align-last: center;
  margin-top: 29px;
}

/*------------MATERIAL UI FILE UPLOADER---------------*/
.forget-password {
  cursor: pointer;
  text-align: end;
  color: mediumblue;
}

.forget-password-link {
  padding: 0px 16px !important;
}

.signup {
  cursor: pointer;
  text-align: start;
  color: mediumblue;
}

.signup-link {
  padding: 0px 16px !important;
}
.reset-password-header {
  width: 70%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.table-scroll {
  max-height: 70vh;
  overflow-y: scroll;
}

.MuiDropzonePreviewList-image {
  display: none !important;
}

.MuiDropzoneArea-root {
  min-height: 150px !important;
}

.apexcharts-radialbar .apexcharts-datalabels-group {
  display: none;
}

/* --------------------- THEME MODIFICATION ------------------------------*/

.MuiButton-outlinedPrimary,
.MuiButton-outlinedPrimary:hover,
.MuiButton-outlinedPrimary:active {
  color: #3f51b5 !important;
  border: 1px solid #3f51b5 !important ;
  background-color: rgba(63, 81, 181, 0.04) !important;
}

.PrivateTabIndicator-colorSecondary-202 {
  background-color: blueviolet;
}

.info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-container > div:first-child {
  margin-left: 20px;
}

.info-heading {
  font-weight: bold;
  font-size: 17px;
  color: black;
  margin-bottom: 5px;
}

.info-list {
  line-height: 1.2;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-style: italic;
  font-size: 14px;
}

.hidden-input .MuiInput-underline.Mui-error:after,
.MuiInput-underline.Mui-disabled:before {
  display: none;
}

.graph-heading-blue {
  color: #0064c1;
  font-size: 15px;
  text-align: left;
  font-weight: 600;
  /* margin-top: 1vw; */
  margin-left: 1vw;
  margin-bottom: 0.5vw;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 140px !important;
  }
}
