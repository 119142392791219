.extraClass {
  pointer-events: auto !important;
  width: 4vw !important;
  height: 4vw !important;
  text-align: center !important;
  align-content: center !important;
}
.extraClass p{
  font-size: 10px !important;

  margin-top: -5px !important;
  line-height: normal !important;
}
.canvasImage{
  height: "2vw";
   width: "2vw";

}
.canvasImage:hover{
  cursor: pointer;
}